import { title } from '@core/utils/filter';

export default [
  {
    key: 'nickname',
    label: 'Nickname',
    formatter: title,
    sortable: true,
    visible: true,
  },
  {
    key: 'source',
    label: 'Source',
    sortable: false,
    visible: true,
  },
  {
    key: 'mobile',
    label: 'Mobile',
    sortable: false,
    visible: true,
  },
  {
    key: 'status',
    label: 'Status',
    sortable: false,
    visible: true,
  },
  {
    key: 'program',
    label: 'Fanpage',
    sortable: false,
    visible: true,
  },
  {
    key: 'recomendations',
    label: 'Recommendations',
    sortable: false,
    visible: true,
  },
  {
    key: 'owner',
    label: 'Owner',
    sortable: false,
    visible: true,
  },
  {
    key: 'catcher',
    label: 'Catcher',
    sortable: false,
    visible: true,
  },
  {
    key: 'tracking',
    label: 'Tracking',
    sortable: false,
    visible: true,
  },
  {
    key: 'potential',
    label: 'Potential',
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: 'actions',
    label: 'Actions',
    sortable: false,
    visible: false,
  },
  {
    key: 'client_ceo',
    label: 'client of ceo',
    sortable: false,
    visible: true,
  },

];
