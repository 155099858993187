<template>
  <b-dropdown variant="link" no-caret>
    <template #button-content>
      <feather-icon
        icon="MoreVerticalIcon"
        size="16"
        class="align-middle text-body"
      />
    </template>

    <b-dropdown-item @click="sendToInCatcher" v-if="!viewActions">
      <feather-icon icon="CornerUpLeftIcon"></feather-icon>
      <span class="align-middle ml-50"> IN CATCHER </span>
    </b-dropdown-item>

    <b-dropdown-item @click="sendToRecovery" v-if="!viewActions">
      <feather-icon icon="CornerUpLeftIcon"></feather-icon>
      <span class="align-middle ml-50"> RECOVERY </span>
    </b-dropdown-item>

    <b-dropdown-item @click="sendToApprove" v-if="viewActions">
      <feather-icon icon="CornerUpLeftIcon"></feather-icon>
      <span class="align-middle ml-50"> APPROVE </span>
    </b-dropdown-item>

    <b-dropdown-item @click="sendToDisapprove" v-if="viewActions">
      <feather-icon icon="CornerUpLeftIcon"></feather-icon>
      <span class="align-middle ml-50"> DISAPPROVE </span>
    </b-dropdown-item>

    <b-dropdown-item @click="deleteLead" v-if="(isCeo || isSupervisor) && !viewActions">
      <feather-icon icon="TrashIcon" class="text-danger"></feather-icon>
      <span class="align-middle ml-50 text-danger"> Delete </span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  data(){
    return {
      viewAction: false,
    };
  },
  props: {
    id: {
      type: Number,
    },
    typee:{
      type: Number,
    }
  },
  computed: {
    viewActions(){
      if(this.typee == 5){
        this.viewAction = true;
      }else{
        this.viewAction = false;
      }
      return this.viewAction;
    },
  },
  methods: {
    sendToInCatcher() {
      this.$emit("onProcessLead", this.id, 1);
    },
    sendToRecovery() {
      this.$emit("onProcessLead", this.id, 5);
    },
    sendToApprove() {
      this.$emit("onProcessLead", this.id, 7);
    },
    sendToDisapprove() {
      this.$emit("onProcessLead", this.id, null);
    },
    deleteLead() {
      this.$emit("onDeleteLead", this.id);
    },
  },
  created(){
    //
  }
};
</script>

<style>
</style>