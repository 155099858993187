<template>
  <div>
    <div
      style="padding-top: 5px"
      :class="!editStatusEdition ? '' : 'd-none'"
      class="w-100"
    >
      <label
        :for="'stat-' + leadId + 'potential'"
        class="cursor-pointer"
        style="padding-left: 15px"
        @click="enableStatusEdition()"
      >
        <b-badge
          pill
          :variant="statusVariant(localPotentialStatus)"
          class="px-1 cursor-pointer unselectable-text"
          style="font-size: 12px"
        >
          {{ currentSelectedOptionName }}
          <feather-icon icon="ChevronsDownIcon" size="20" class="text-white" />
        </b-badge>
      </label>
      <feather-icon
        icon="ListIcon"
        size="15"
        class="text-info ml-1 cursor-pointer"
        @click="showModalTrackingPotentialStatus = true"
      />
    </div>
    <v-select
      v-if="editStatusEdition"
      v-model="localPotentialStatus"
      :input-id="'stat-' + leadId + 'potential'"
      :reduce="(red) => red.id"
      class="text-center cursor-pointer select-size-sm"
      :options="options"
      label="answer"
      style="width: 220px; margin-left: 2px"
      :clearable="false"
      :append-to-body="true"
      @input="updatePotentialStatus()"
      @close="closePotentialStatus()"
    >
      <template #option="{ id, answer }">
        <b-badge
          class="mr-1 text-center"
          :variant="statusVariant(id)"
          style="font-size: 12px"
        >
          {{ answer }}
        </b-badge>
      </template>
    </v-select>

    <modal-tracking-potential-status
      v-if="showModalTrackingPotentialStatus"
      :leadId="leadId"
      :name="leadName"
      @hidden="showModalTrackingPotentialStatus = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalTrackingPotentialStatus from "@/views/social-network/views/leads/components/ModalTrackingPotentialStatus.vue";
export default {
  components: {
    ModalTrackingPotentialStatus,
  },
  props: {
    leadId: {
      type: Number,
      required: true,
    },
    potentialStatus: {
      type: Number,
      required: true,
    },
    leadName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModalTrackingPotentialStatus: false,
      editStatusEdition: false,
      localPotentialStatus: this.potentialStatus,
      options: [
        {
          id: 1,
          answer: "YES",
        },
        {
          id: 2,
          answer: "NO",
        },
      ],
    };
  },
  computed: {
    currentSelectedOptionName() {
      return this.options.find(
        (option) => option.id === this.localPotentialStatus
      ).answer;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  created() {},
  methods: {
    ...mapActions({
      A_CHANGE_STATUS_POTENTIAL:
        "SocialNetworkLeadsStore/A_CHANGE_POTENTIAL_STATUS",
    }),
    async changePotentialLeadStatus() {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) {
          this.localPotentialStatus = this.potentialStatus;
          this.editStatusEdition = false;
          return;
        }
        const params = {
          lead_id: this.leadId,
          status_potential: this.localPotentialStatus,
          user_id: this.currentUser.user_id,
        };
        const response = await this.A_CHANGE_STATUS_POTENTIAL(params);
        if (response.status_code === 200) {
          this.showSuccessSwal(
            "Success",
            `The potential status of Lead ${this.leadName} has been updated.`
          );
          this.$emit("update");
        } else {
          this.localPotentialStatus = this.potentialStatus;
          this.editStatusEdition = false;
          this.showErrorSwal();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async enableStatusEdition() {
      this.editStatusEdition = true;
    },
    statusVariant(status) {
      switch (status) {
        case 1:
          return "success";
        case 2:
          return "danger";
        default:
          return "warning";
      }
    },
    async updatePotentialStatus() {
      await this.changePotentialLeadStatus();
      this.editStatusEdition = false;
    },
    closePotentialStatus() {
      this.editStatusEdition = false;
    },
  },
};
</script>
