<template>
  <div>
    <b-modal
      ref="modal-tracking-potential-status"
      size="lg"
      @hidden="$emit('hidden')"
      hide-footer
    >
      <template #modal-title>
        <span class="text-uppercase font-weight-bolder"> Tracking </span>
      </template>
      <template #default>
        <b-input-group class="my-2">
          <template #prepend>
            <b-input-group-text>LEAD NAME</b-input-group-text>
          </template>
          <b-form-input :value="name" readonly />
        </b-input-group>

        <b-table-simple>
          <b-thead>
            <b-tr>
              <b-th>CREATED BY</b-th>
              <b-th>STATUS</b-th>
              <b-th>CREATED AT</b-th>
              <b-th>UPDATED AT</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(item, index) in items" :key="index">
              <b-td> {{ item.user_name }} </b-td>
              <b-td>
                <b-badge
                  class="text-center"
                  :variant="statusVariant(item.status_potential)"
                >
                  {{ item.status_potential_name }}
                </b-badge>
              </b-td>
              <b-td> {{ item.created_at | myGlobalDay }} </b-td>
              <b-td> {{ item.updated_at | myGlobalDay }} </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </template>
    </b-modal>
  </div>
</template>
<script>
import TrackingPotentialStatusService from "@/views/social-network/views/leads/components/services/tracking-potential-status.service.js";
export default {
  name: "ModalTrackingPotentialStatus",
  props: {
    leadId: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {},
  created() {
    this.getTrackingPotentialStatus();
  },
  mounted() {
    this.toggleModal("modal-tracking-potential-status");
  },
  methods: {
    async getTrackingPotentialStatus() {
      try {
        this.addPreloader();
        const params = {
          lead_id: this.leadId,
        };
        const { data } =
          await TrackingPotentialStatusService.getTrackingPotentialStatusList(
            params
          );
        this.items = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    statusVariant(status) {
      const variants = {
        1: "light-success",
        2: "light-danger",
        3: "light-primary",
        4: "light-info",
      };
      return variants[status];
    },
  },
};
</script>
<style lang="scss">
</style>