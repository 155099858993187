import { amgApi } from "@/service/axios";

class TrackingPotentialStatusService {
  async getTrackingPotentialStatusList(params) {
    try {
      const data = await amgApi.post(
        "/commons/lead-potential-status/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new TrackingPotentialStatusService();
